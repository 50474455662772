.text-custom {
    color: #8b2323;
}

.custom-font {
    font-family: 'Merienda', cursive;
}

.custom-bg-colour1 {
    background-color: #fff1cf;
}

.custom-bg-colour2 {
    background-color: #BBD5ED;
}

.custom-bg-colour3 {
    background-color: #EAF2EF;
}